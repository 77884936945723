import styled from 'styled-components';
import IconSearchLocations from '../../images/icon-search-locations.png';

export const MainContainer = styled.div`
  display: block;
  padding: 0 110px 80px 110px;
  margin-top: -30px;
  font-family: 'Figtree', arial, sans-serif;
  font-size: 18px;
  line-height: 32px;
  color: rgba(0, 0, 0, 0.8);
  @media (max-width: 1399px) {
    margin-top: -22px;
    font-size: 16px;
    line-height: 25px;
  }
  @media (max-width: 1199px) {
    padding: 0 50px 50px 50px;
  }
  @media (max-width: 991px) {
    margin-top: -75px;
  }
  @media (max-width: 767px) {
    padding: 35px;
    padding-top: 0;
    font-size: 15px;
    line-height: 22px;
  }
`;

export const LocationsTop = styled.div`
  position: relative;
  margin-top: 220px;
  font-family: 'HelveticaNeue-Light', arial, sans-serif;
  font-size: 20px;
  line-height: 32px;
  color: rgba(0, 0, 0, 0.6);
  @media (max-width: 1399px) {
    margin-top: 210px;
    font-size: 20px;
    line-height: 32px;
  }
  @media (max-width: 1199px) {
    margin-top: 310px;
  }
  @media (max-width: 991px) {
    margin-top: 0;
  }
`;

export const LocationTopClearFix = styled(LocationsTop)``;

export const LocationsTopSearch = styled.div`
  position: absolute;
  z-index: 10;
  top: 150px;
  right: 110px;
  width: 500px;
  height: 65px;
  background: #fff url(${IconSearchLocations}) no-repeat center right;
  background-size: auto 30px;
  border-radius: 40px;
  box-shadow: 0 0 25px rgba(0, 0, 0, 0.15);
  @media (max-width: 1199px) {
    left: 0;
    right: 0;
    margin: 0 auto;
  }
  @media (max-width: 767px) {
    position: relative;
    top: 25px;
    width: 90%;
    height: 50px;
    background-image: none;
  }
`;

export const LocationsTopSearchInput = styled.div`
  float: left;
  width: 72%;
  height: 65px;
  @media (max-width: 767px) {
    height: 50px;
    width: 80%;
  }
`;

export const LocationsTopSearchInputInput = styled.input`
  width: 100%;
  height: 65px;
  padding: 30px 30px;
  background: none;
  border: none;
  font-family: 'Figtree', arial, sans-serif;
  font-size: 18px;
  line-height: 18px;
  color: rgba(0, 0, 0, 0.6);
  opacity: 0.7;
  @media (max-width: 767px) {
    height: 50px;
    padding: 20px;
    font-size: 15px;
    line-height: 15px;
  }
`;

export const LocationsTopSearchSubmit = styled.div`
  float: right;
  width: 28%;
  height: 65px;
  @media (max-width: 767px) {
    height: 50px;
    width: 20%;
  }
`;

export const LocationsTopSearchSubmitInput = styled.input`
  width: 100%;
  height: 60px;
  padding-top: 3px;
  padding-right: 95px;
  font-family: 'Figtree', arial, sans-serif;
  font-size: 18px;
  line-height: 18px;
  text-align: right;
  color: #55aa4c;
  background: none;
  cursor: pointer;
  border: none !important;
  @media (max-width: 767px) {
    height: 50px;
    padding-top: 0;
    padding-right: 20px;
    font-size: 15px;
    line-height: 15px;
  }
`;

export const LocationsBody = styled.div`
  padding-top: 60px;
`;

export const GoogleMapContainer = styled.div`
  position: relative;
  overflow: hidden;
  height: 700px;
  @media (max-width: 1199px) {
    height: 600px;
  }
  @media (max-width: 767px) {
    height: 500px;
    top: -65px;
  }
`;

export const ServiceCoverage = styled.div`
  display: block;
  :after {
    visibility: hidden;
    display: block;
    font-size: 0;
    content: ' ';
    clear: both;
    height: 0;
  }
`;

export const LeftContent = styled.div`
  float: left;
  width: 34%;
  @media (max-width: 991px) {
    width: 100%;
    margin-bottom: 30px;
  }
`;

export const RightContent = styled.div`
  float: right;
  width: 66%;
  padding-left: 75px;
  @media (max-width: 991px) {
    width: 100%;
    padding-left: 0;
  }
`;

export const HeaderH2 = styled.h2`
  position: relative;
  margin: 0 0 35px 0;
  font-family: 'Adelle-Bold', sans-serif;
  color: #0e4c80;
  font-size: 40px;
  line-height: 45px;
  padding: 0;
  font-weight: normal;
  font-style: normal;
  @media (max-width: 1399px) {
    font-size: 35px;
    line-height: 40px;
    margin-bottom: 20px;
  }
  @media (max-width: 1199px) {
    font-size: 30px;
    line-height: 35px;
  }
  @media (max-width: 767px) {
    font-size: 22px;
    line-height: 26px;
  }
`;

export const HeaderH3 = styled.h3`
  margin: 0 0 35px 0;
  font-family: 'Figtree', sans-serif;
  font-size: 28px;
  line-height: 35px;
  color: #55aa4c;
  @media (max-width: 1399px) {
    font-size: 25px;
    line-height: 30px;
    margin-bottom: 30px;
  }
  @media (max-width: 767px) {
    font-size: 20px;
    line-height: 25px;
  }
`;

export const ParaText = styled.div`
  margin: 0 0 40px 0;
  padding: 0;
  @media (max-width: 1399px) {
    margin: 0 0 30px 0;
  }
`;

export const ImageStyle = styled.img`
  margin-bottom: 0;
  width: 100%;
  height: auto;
  border: 0;
  vertical-align: top;
`;

export const LocationsTopAlert = styled.div`
  padding: 35px;
  font-family: 'Figtree', arial, sans-serif;
  font-size: 18px;
  line-height: 22px;
  text-align: center;
  color: #fff;
  background: #0e4c80;
  @media (max-width: 1199px) {
    font-size: 16px;
    line-height: 20px;
  }
  @media (max-width: 767px) {
    padding-top: 0;
    padding-bottom: 30px;
    font-size: 14px;
    line-height: 18px;
  }
  :after {
    visibility: hidden;
    display: block;
    font-size: 0;
    content: ' ';
    clear: both;
    height: 0;
  }
`;

export const HeaderH1 = styled.h1`
  margin: 0;
  padding: 0;
  font-family: 'Adelle-Bold', sans-serif;
  font-size: 30px;
  line-height: 32px;
  text-transform: uppercase;
  color: #fff;
  @media (max-width: 1199px) {
    font-size: 25px;
    line-height: 27px;
  }
`;
